import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2014 - 2019",
      title: "Physics with Electronics",
      degree: "Higher National Diploma",
      desc: "Electronics is a branch of physics that deals with the behavior and control of electrons and other charged particles in various materials and devices. Physics provides the fundamental principles and theoretical framework that underlie electronic devices and technologies.",
    },
    // {
    //   yearRange: "2013 - 2015",
    //   title: "Science Lab Tech",
    //   degree: "National Diploma",
    //   desc: "This is a branch of science that involves the use of laboratory instruments, techniques, and procedures to investigate and analyze various scientific phenomena. The field of science laboratory technology covers a wide range of scientific disciplines, such as biology, chemistry, physics, and environmental science..",
    // },
    // {
    //   yearRange: "2007 - 2013",
    //   title: "Science",
    //   degree: "High School",
    //   desc: "Science is a systematic and empirical approach to acquiring knowledge about the natural world through observation, experimentation, and analysis.",
    // },
  ];

  const experienceDetails = [
    {
      yearRange: "2021 - 2023",
      title: "Software Developer",
      place: "Xphiz Digital",
      desc: "I successfully developed and deployed engaging and market-fit software applications.",
    },
    {
      yearRange: "2020 - 2021",
      title: "Frontend Developer",
      place: "Tithcqo",
      desc: "I used HTML, CSS, Javascript and React to develop frontend excellently during my time of services",
    },
    {
      yearRange: "2019 - 2021 ",
      title: "Business Analyst",
      place: "Tithcqo",
      desc: "I work within the core of my organization with my business analysis capabilities to improve and streamline processes that help my organization meet its objectives and reach goals.",
    },
  ];

  const skills = [
    {
      name: "Reactjs",
      percent: 90,
    },
    {
      name: "Nodejs",
      percent: 65,
    },
    {
      name: "JavaScript",
      percent: 80,
    },
    {
      name: "HTML",
      percent: 95,
    },
    {
      name: "CSS",
      percent: 90,
    },
    {
      name: "Wordpress",
      percent: 95,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
